import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import {LoadingIndicator} from '../../components/Common'
import { useEquipment } from '../../hooks/equipmentHook.js';
import { TextAreaField } from '../forms/TextAreaField.js';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as url from "../../helpers/jengasmart_api_urls.js";
import axios from "axios";


export function ImportTasksModal({isOpen, onCloseClick, projectId}){
    const [isitemSource,setItemSource] = useState(true)
    const {companyInventory} = useCompanyInventory()
    const [file, setFile] = useState(null);
    const [isSaving, setIsSaving] = useState(false)
    const { addInvEquipment, isImportTasksLoading } = useEquipment(onCloseClick, projectId)

    const importTasksForm = useForm();
    const [isTouched, setIsTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (values) => {
        setIsSaving(true);
        const user = JSON.parse(localStorage.getItem('authUser'));

        const config = {
            headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + user.token,
            },
        };

        const bodyFormData = new FormData();
        bodyFormData.append('projectId', projectId);
        bodyFormData.append('userId', user.user.id);
        bodyFormData.append('tasksFile', file);

        const apiUrl = `${process.env.REACT_APP_API_BASE}${url.POST_TASKS_DETAILS}/import/${projectId}`;

        try {
            const res = await axios.post(apiUrl, bodyFormData, config);
            if (res.data.success) {
                toast.success('Tasks imported successfully');
                setIsSaving(false);
                onCloseClick();
            }
        } catch (error) {
            toast.error('Error importing tasks');
            setIsSaving(false);
            console.error(error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setIsTouched(true);
    
        if (!selectedFile) {
          setErrorMessage('Please add a CSV file');
        } else {
          setErrorMessage('');
        }
    };
    
    const showError = !file && isTouched;


    return (
      <Modal isOpen={isOpen} centered={true} size={"lg"}>
        <Formiz connect={importTasksForm} onValidSubmit={handleSubmit}>
          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={importTasksForm.submit}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Importing Tasks
              </h5>
              <button
                type="button"
                onClick={onCloseClick}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex flex-column w-100">
            <div className="d-flex flex-row">
                
                <div className="col-6 p-2">
                  <div className="mb-1">
                    <label className="form-Label">Task CSV File</label>
                    <input
                        type="file"
                        name="tasksFile"
                        required
                        onChange={handleFileChange}
                        onBlur={() => setIsTouched(true)}
                        className={`form-control ${showError ? 'is-invalid' : ''}`}
                        aria-invalid={showError}
                        aria-describedby={showError ? 'file-error' : null}
                        style={{
                        background: '#00000008',
                        }}
                    />
                    {showError && (
                        <div id="file-error" className="invalid-feedback d-block">
                        {errorMessage || 'Please add a CSV file'}
                        </div>
                    )}
                  </div>

                </div>
              </div>
              
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={onCloseClick}
                  className="btn btn-secondary "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <LoadingIndicator />
                  ) : (
                    <span>Import Schedule</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

ImportTasksModal.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}